import axios from 'axios';

import { pushEvent } from '@bbcom/bb-analytics/app/bb/analytics/event';
import { loginStateChanged } from '@bbcom/bb-analytics/app/bb/analytics/event';
import { endpoint } from '../login';

export const sendGAEvent = ({
  event, category, action, label,
}) => {
  window.dataLayer = window.dataLayer || [];
  console.log(event, category, action, label);
  window.dataLayer.push({
    event: 'bbEvent.click',
    bbEvent: {
      event,
      category,
      action,
      label,
    },
  });
};

export const gaTrackSignup = async () => {
  pushEvent('register', 'user', 'registration', 'complete');
}

export const gaTrackLogin = async () => {
  const gaProfile = await axios.get(`${endpoint}/profile/ga`, { withCredentials: true });
  loginStateChanged(
      true,
      'logged in',
      'login-page',
      {
          component: 'components/signin',
          itemSource: { name: 'combined-signin' }
      },
      gaProfile.data
  );
}