const API_BASE_URL = "https://api.bodybuilding.com/profile";

const getQueryParam = (param) => {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(param);
};

export const activateAccount = async () => {
	const userId = getQueryParam("userid");
	const activationId = getQueryParam("activationid");

	if (!userId || !activationId) {
		throw new Error("Missing activation parameters.");
	}

	const url = `${API_BASE_URL}/${userId}/activation`;
	const body = JSON.stringify({ activationCode: activationId });

	const response = await fetch(url, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body,
	});

	if (!response.ok) {
		throw new Error("Activation failed.");
	}

	return response.json();
};
