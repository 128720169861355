import React from 'react';

import './TabHeader.scss';

const TabHeader = ({ selectedTab, handleSelect }) =>
    <div className="combined-sign-in--tab-headers ">
        <a
            id="signin"
            className={
                "combined-sign-in--tab-header combined-sign-in--signin-tab "
                + (
                    selectedTab === "signin"
                        ? 'combined-sign-in--selected-tab'
                        : ''
                )
            }
            onClick={ handleSelect }
            href='/'
        >
            Sign in
        </a>
        <a
            id="signup"
            className={
                "combined-sign-in--tab-header combined-sign-in--signup-tab "
                + (
                    selectedTab === "signup"
                        ? 'combined-sign-in--selected-tab'
                        : ''
                )
            }
            onClick={ handleSelect }
            href='/'
        >
            Create Account
        </a>
    </div>;

export default TabHeader;
