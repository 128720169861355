import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

const ResetPassword = ({ passwordService }) => {
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isResetting, setIsResetting] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [passwordsMatch, setPasswordsMatch] = useState(true);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Reset Password | Bodybuilding.com";

		// Check if "token" exists in URL, otherwise redirect
		if (!searchParams.get("token")) {
			navigate("/forgot-password", { replace: true });
		}
	}, [searchParams, navigate]);

	const handleNewPasswordChange = (e) => {
		setNewPassword(e.target.value);
		setPasswordsMatch(e.target.value === confirmPassword);
	};

	const handleConfirmPasswordChange = (e) => {
		setConfirmPassword(e.target.value);
		setPasswordsMatch(newPassword === e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!passwordsMatch) {
			setErrorMessage("Passwords do not match.");
			return;
		}

		setIsResetting(true);
		setErrorMessage("");

		try {
			const token = searchParams.get("token");
			await passwordService.reset(newPassword, token);
			alert("Password reset successfully!");
			navigate("/"); // Redirect to homepage or login
		} catch (error) {
			setErrorMessage(error.message || "Failed to reset password.");
		} finally {
			setIsResetting(false);
		}
	};

	return (
		<div className="UserAct">
			<h2 className="UserAct-h2">Reset Your Password</h2>
			<p className="UserAct-p">Enter your new password.</p>
			<form className="UserAct-form" onSubmit={handleSubmit}>
				<div className="bb-input-wrap bb-input-in-field-wrap">
					<input
						type="password"
						name="newPassword"
						className="bb-input UserAct-input"
						value={newPassword}
						onChange={handleNewPasswordChange}
						required
					/>
					<label className="bb-label">New Password</label>
				</div>
				<div className="bb-input-wrap bb-input-in-field-wrap">
					<input
						type="password"
						name="confirmPassword"
						className="bb-input UserAct-input"
						value={confirmPassword}
						onChange={handleConfirmPasswordChange}
						required
					/>
					<label className="bb-label">Confirm Password</label>
				</div>
				{!passwordsMatch && (
					<p className="error-message" style={{ color: "red" }}>Passwords do not match!</p>
				)}
				{errorMessage && <p className="error-message">{errorMessage}</p>}
				<button type="submit" className="UserAct-btn" disabled={isResetting || !passwordsMatch}>
					{isResetting ? "Submitting..." : "Reset Password"}
				</button>
			</form>
		</div>
	);
};

export default ResetPassword;
