import React, { Component } from 'react';
import { Signin, Signup, TabHeader } from './components';
import './CombinedSignin.scss';

const imageUrls = [
    "https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bga.png?v=1739881916",
    "https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgb.jpg?v=1739881915",
    "https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgc.jpg?v=1739881916",
    "https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgd.jpg?v=1739881916",
    "https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bge.jpg?v=1739882052",
    "https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgf.jpg?v=1739882051"
];

class CombinedSignin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'signin',
            currentImageIndex: 0,
            fade: false,
        };
        this.imageInterval = null; // Store interval reference
    }

    handleSelect = (e) => {
        e.preventDefault();
        this.setState({ selectedTab: e.target.id });
    };

    componentDidMount() {
        this.startImageTransition();
    }

    componentWillUnmount() {
        clearInterval(this.imageInterval); // Clear interval on unmount
    }

    startImageTransition = () => {
        this.imageInterval = setInterval(() => {
            this.setState(prevState => ({
                currentImageIndex: (prevState.currentImageIndex + 1) % imageUrls.length
            }));
        }, 5000);
    };

    render() {
        const { selectedTab, currentImageIndex } = this.state;

        return (
            <div className="combined-sign-in--container">
                {/* Sidebar Image */}
                <div className="slider-container">
                    {imageUrls.map((img, index) => (
                        <img
                            key={index}
                            src={img}
                            alt="Slideshow"
                            className={`slider-image ${index === currentImageIndex ? "active" : ""}`}
                        />
                    ))}
                </div>

                {/* Sign-in Form */}
                <div className="combined-sign-in--form">
                    <img
                        src="https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bbcom-logos-tm-rgb-blue-wordmark_1.png?v=1710959472"
                        alt="Bodybuilding.com Logo"
                        className="combined-sign-in--logo"
                    />

                    <TabHeader selectedTab={selectedTab} handleSelect={this.handleSelect} />

                    <div className="combined-sign-in--signin-signup">
                        <Signin name="signin" isSelected={selectedTab === 'signin'} />
                        <Signup name="signup" isSelected={selectedTab === 'signup'} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CombinedSignin;
