import axios from 'axios';
import { get } from 'lodash';
import { loginSuccess } from './login';

let loginResolvePromise = null;
let loginRejectPromise = null;
let isMounted = true; // Track component mount status
let handleAppleLoginSuccess = null; // Store reference to the event handler

export const loginWithApple = async () => {
  return new Promise(async (resolve, reject) => {
    if (!window.AppleID) {
      reject(new Error("Apple Sign-In is not available."));
      return;
    }

    loginResolvePromise = (value) => {
      if (isMounted) resolve(value);
    };
    loginRejectPromise = (error) => {
      if (isMounted) reject(error);
    };

    handleAppleLoginSuccess = async (appleData) => {
      const code = get(appleData, 'detail.authorization.code');
      const id_token = get(appleData, 'detail.authorization.id_token');
      const email = get(appleData, 'detail.user.email');
      const firstName = get(appleData, 'detail.user.name.firstName');
      const lastName = get(appleData, 'detail.user.name.lastName');
      const state = get(appleData, 'detail.authorization.state');
      const user = get(appleData, 'detail.user.userIdentifier', ''); 
      try {
        const response = await loginToBodySpaceWithApple(code, id_token, user, email, firstName, lastName, state);
        if (response?.data?.user?.shop_url) {
          window.location.href = response.data.user.shop_url;
        }
        loginResolvePromise(response);
      } catch (error) {
        loginError(error);
      } finally {
        cleanup();
      }
    };

    // Add event listener with stored reference
    window.document.addEventListener('AppleIDSignInOnSuccess', handleAppleLoginSuccess);

    try {
      await window.AppleID.auth.signIn();
    } catch (error) {
      loginError(error);
      cleanup();
    }
  });
};

// API Call Function
const loginToBodySpaceWithApple = async (code, id_token, user, email, firstName, lastName, state) => {
  const params = {
    authorizationCode: code,
    identityToken: id_token,
    user: user,
    email: email,
    fullName: {
      familyName: lastName,
      givenName: firstName,
    },
    state: state,
  };

  return axios.post('https://api.bodybuilding.com/v2/login/apple', params, { cache: false });
};

// Handle Login Errors
export const loginError = (err) => {
  if (!isMounted) return;

  if (err.response && err.response.status === 401) {
    loginRejectPromise(new Error(err.response.data.message));
  } else {
    loginRejectPromise(new Error(err.message));
  }
};

// Cleanup function to remove event listeners and prevent memory leaks
const cleanup = () => {
  isMounted = false;
  if (handleAppleLoginSuccess) {
    window.document.removeEventListener('AppleIDSignInOnSuccess', handleAppleLoginSuccess);
    handleAppleLoginSuccess = null;
  }
};
