import React, { useState, useEffect } from "react";
import '../CombinedSignin.scss';
import passwordService from "../services/passwordService";

const imageUrls = [
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bga.png?v=1739881916",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgb.jpg?v=1739881915",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgc.jpg?v=1739881916",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgd.jpg?v=1739881916",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bge.jpg?v=1739882052",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgf.jpg?v=1739882051"
];

const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const [isSending, setIsSending] = useState(false);
	const [isSendSuccess, setIsSendSuccess] = useState(false);
	const [requestErrorMessage, setRequestErrorMessage] = useState(null);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	// Dynamically update the title
	useEffect(() => {
		document.title = "Reset Password | Bodybuilding.com";
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSending(true);
		setRequestErrorMessage(null);
		setIsSendSuccess(false);

		try {
			await passwordService.forgot(email);
			setIsSendSuccess(true);
		} catch (error) {
			setRequestErrorMessage(error.message || "An error occurred.");
		} finally {
			setIsSending(false);
		}
	};

	return (
		<div className="password-change-container combined-sign-in--container">
			{/* Sidebar Image */}
			<div className="slider-container">
				{imageUrls.map((img, index) => (
					<img
						key={index}
						src={img}
						alt="Slideshow"
						className={`slider-image ${index === currentImageIndex ? "active" : ""}`}
					/>
				))}
			</div>

			{/* Forgot Password Form */}
			<div className="password-change-form">
				<div>
					<img
						src="https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bbcom-logos-tm-rgb-blue-wordmark_1.png?v=1710959472"
						alt="Bodybuilding.com Logo"
						className="combined-sign-in--logo"
					/>
					<h2>Reset Your Password</h2>
					<p>Enter your email and we’ll send a link to reset your password.</p>

					{!isSendSuccess ? (
						<form className="password-form" onSubmit={handleSubmit}>
							<div className="input-group">
								<input
									className="input-box"
									type="email"
									placeholder="Email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									maxLength="128"
									required
								/>
							</div>

							<div className="combined-sign-in--errors">
								{requestErrorMessage && <p className="error-message">{requestErrorMessage}</p>}
							</div>

							<button
								type="submit"
								className="combined-sign-in--button"
								disabled={isSending || !email}
							>
								{isSending ? "Submitting..." : "Reset Password"}
							</button>
						</form>
					) : (
						<div className="UserAct-submitMsg">
							<span className="UserAct-submitMsg__success">
								Check your email ({email}) for a link to reset your password.<br /><br />
								If it doesn’t appear within a few minutes, check your spam folder.
							</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
