const API_BASE_URL = "https://api.bodybuilding.com/profile/password";

const passwordService = {
	validate(password) {
		const requirements = [
			{ key: "MIN_LENGTH", validator: (value) => value.trim().length >= 8 },
			{ key: "LOWERCASE", validator: (value) => /[a-z]/.test(value) },
			{ key: "UPPERCASE", validator: (value) => /[A-Z]/.test(value) },
			{ key: "NUMBER", validator: (value) => /[0-9]/.test(value) },
			{ key: "SPECIAL_CHARACTER", validator: (value) => /\W|_/.test(value) },
		];

		const validItems = requirements
			.filter((req) => req.validator(password))
			.map((req) => req.key);

		return {
			isValid: validItems.includes("MIN_LENGTH") && validItems.length >= 4,
			requirementsMet: validItems,
		};
	},

	async forgot(email) {
		try {
			const response = await fetch(`${API_BASE_URL}/forgot`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ emailAddress: email }),
			});

			if (!response.ok) throw new Error("Failed to send reset email");
			return { success: true };
		} catch (error) {
			return { success: false, error: error.message };
		}
	},

	async reset(token, password) {
		try {
			const response = await fetch(`${API_BASE_URL}/forgot/change`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ token, password }),
			});

			if (!response.ok) throw new Error("Failed to reset password");
			return await response.json();
		} catch (error) {
			return { success: false, error: error.message };
		}
	},

	async change(currentPassword, newPassword, userId) {
		try {
			const response = await fetch(`${API_BASE_URL}/change`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ userId, current: currentPassword, password: newPassword }),
			});

			if (!response.ok) throw new Error("Failed to change password");
			return { success: true };
		} catch (error) {
			return { success: false, error: error.message };
		}
	},
};

export default passwordService;
