import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Lottie from "react-lottie";
import animationData from "../../assets/media/white-spinner.json";

import { CustomImageInput } from "../sharedComponents";
// import facebookLogo from "../../assets/media/fb-icon.png";
import appleLogo from "../../assets/media/apple-icon.png";

import {
  login,
  loginWithFacebook,
  loginWithApple,
  sendGAEvent,
} from "../../services";

const RECAPTCHA_KEY = "6LffBTYUAAAAAOVu998M6lU1Y-yMY0bkyPjcccTj";

const fieldTypes = ["username", "password"];

class Signin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      credentials: {
        grant_type: "password",
        username: "",
        password: "",
        device_id: "bbcom_web_login"
      },
      errors: {
        signin: "",
        facebookOrApple: "",
      },
      fbLoading: false,
      appleLoading: false,
      bbcomLoading: false,
    };
    this._reCaptchaRef = React.createRef();
  }

  componentDidMount() {
    const config = {
      clientId: "com.bodybuilding.web",
      scope: "name email",
      redirectURI: window.location.origin + "/login/apple/redirect",
      usePopup: true,
    };

    window.AppleID.auth.init(config);
  }

  handleInputChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const name = target.name;

    this.setState((state) => {
      const credentials = Object.assign({}, state.credentials, {
        [name]: target.value,
      });

      return { credentials };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    sendGAEvent({
      event: "button_click",
      category: "profile",
      action: "login",
      label: "bbcom",
    });

    console.log("signing in to bbcom");
    this.setState({ bbcomLoading: true });

    login(this.state.credentials, this.getCaptcha)
      .catch((err) => {
        const message = err.message || "Login failed"; // Ensure message is defined

        sendGAEvent({
          event: "failure",
          category: "profile",
          action: "login_failed",
          label: "bbcom " + message, // Log error message
        });

        this._setError({ signin: message }); // Set error message in state
        this.setState({ bbcomLoading: false });
      });

  };

  handleFacebookClick = () => {
    if (!window.FB || !window.FB.getLoginStatus) {
      this._setError({ facebookOrApple: "Facebook is not connected." });
      return;
    }

    this.setState({ fbLoading: true });

    sendGAEvent({
      event: "button_click",
      category: "profile",
      action: "login",
      label: "facebook",
    });

    loginWithFacebook().catch((err) => {
      sendGAEvent({
        event: "failure",
        category: "profile",
        action: "login_failed",
        label: "facebook " + err.message,
      });
      this._setError({ facebookOrApple: err.message });
      this.setState({ fbLoading: false });
    });
  };

  _setError(error) {
    const newErrors = { ...this.state.errors, ...error };
    this.setState({ errors: newErrors });
  }

  handleAppleClick = () => {
    sendGAEvent({
      event: "button_click",
      category: "profile",
      action: "login",
      label: "apple",
    });

    window.document.addEventListener("AppleIDSignInOnFailure", (err) => {
      sendGAEvent({
        event: "failure",
        category: "profile",
        action: "login_failed",
        label: "apple",
      });
      this._setError({
        facebookOrApple:
          err.message ||
          "This Apple account is not associated with a an account.",
      });
    });
    this.setState({ appleLoading: true });

    loginWithApple().catch((err) => {
      this._setError({
        facebookOrApple:
          err.message ||
          "This Apple account is not associated with a an account.",
      });
      this.setState({ appleLoading: false });
    });
  };

  getCaptcha = () => {
    return new Promise((success, reject) => {
      this._reCaptchaRef.current
        .executeAsync()
        .then(success)
        .catch((err) => {
          sendGAEvent({
            event: "failure",
            category: "profile",
            action: "login",
            label: "recaptcha failed",
          });
          console.log("getting error from recaptcha");
          reject("ReCAPTCHA failed");
        });
    });
  };

  render() {
    const { credentials, errors } = this.state;
    const { isSelected } = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div
        className={
          "combined-sign-in--signin " + (isSelected ? "" : "hide-on-mobile")
        }
      >
        <h3 className="hide-on-mobile combined-sign-in--desktop-tab">
          Sign in
        </h3>
        <form
          className="combined-sign-in--input-form"
          onSubmit={this.handleSubmit}
        >
          {fieldTypes.map((type) => (
            <CustomImageInput
              key={type}
              fieldType={type}
              onChange={this.handleInputChange}
              value={credentials[type]}
              onBlur={this.handleInputChange}
              autoComplete={true}
              noFeedback={true}
              label={type === "username" ? "Username / Email" : ""}
            />
          ))}
          <div className="combined-sign-in--stay-logged-in--section hidden">
            <div>
              <input
                className="combined-sign-in--stay-logged-in--checkbox"
                id="stay-logged-in"
                type="checkbox"
              />
            </div>
            <label
              htmlFor="stay-logged-in"
              className="combined-sign-in--stay-logged-in--label"
            >
              Selecting this option will keep you logged-in on this device. To
              keep your account secure, use this option only on your personal
              devices.
            </label>
          </div>
          <div
            className={
              "combined-sign-in--errors " +
              (errors.signin.length === 0 ? "hidden" : "")
            }
          >
            <p>{errors.signin}</p>
          </div>
          <ReCAPTCHA
            ref={this._reCaptchaRef}
            sitekey={RECAPTCHA_KEY}
            onChange={this.handleRecaptcha}
            size="invisible"
          />
          <button
            className={
              "combined-sign-in--" +
              (credentials.username && credentials.password
                ? "button"
                : "disabled-button")
            }
            type="submit"
          >
            {this.state.bbcomLoading && (
              <div>
                <Lottie options={defaultOptions} height={30} width={30} />
              </div>
            )}
            &nbsp;&nbsp;Sign In
          </button>
          <a
            className="combined-sign-in--forgot-password"
            href="/forgot-password"
          >
            Forgot your password?
          </a>
          <hr className="combined-sign-in--divider" />
        </form>
        <div
          className={
            "combined-sign-in--errors " +
            (errors.facebookOrApple.length === 0 ? "hidden" : "")
          }
        >
          <p>{errors.facebookOrApple}</p>
        </div>
        <div className="combined-sign-in--alternate-signin">
          <div className="combined-sign-in--alternate-signup--buttons">

            {/* <button
              className="combined-sign-in--facebook-button"
              onClick={this.handleFacebookClick}
            >
              {this.state.fbLoading ? (
                <div>
                  <Lottie options={defaultOptions} height={30} width={30} />
                </div>
              ) : (
                <img src={facebookLogo} alt="facebook logo" />
              )}
              &nbsp;&nbsp;Sign in
            </button> */}
            <button
              className="combined-sign-in--apple-button"
              onClick={this.handleAppleClick}
            >
              {this.state.appleLoading ? (
                <div>
                  <Lottie options={defaultOptions} height={30} width={30} />
                </div>
              ) : (
                <img src={appleLogo} alt="apple logo" />
              )}
              &nbsp;&nbsp;Sign in with Apple
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Signin;
