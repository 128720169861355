import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CombinedSignin from "./CombinedSignin";
import Activate from "./pages/Activate";
import Reset from "./pages/Reset";
import PasswordChange from "./pages/PasswordChange";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

const App = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<CombinedSignin />} />
				<Route path="/activate" element={<Activate />} />
				<Route path="/reset" element={<Reset />} />
				<Route path="/change" element={<PasswordChange />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/reset-password" element={<ResetPassword />} />
			</Routes>
		</Router>
	);
};

export default App;
