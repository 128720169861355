import axios from 'axios';

export const getPasswordErrors = (params) => {
    const passwordPromise = new Promise((res) => {
        if (!params.password) res('Password required');

        const hasRequiredParams = ['password', 'username', 'emailAddress'].every(function(field) {
            return !!params[field].length;
        });

        if (!hasRequiredParams) {
            res('Please fill out all create account fields.');
        } else {

            const endpoint = 'https://api.bodybuilding.com/profile/password/validate-new';

            axios
                .post(endpoint, params)
                .then((bbcomRes) => {
                    res('');
                })
                .catch((err) => {
                    if ((err.response?.data?.ret_code) === 5150) {
                        res(err.response.data.err_msg);
                    } else {
                        res('Unable to connect to bodybuilding.com.');
                    }
                });
        }
    });

    return passwordPromise;
}
