const parseSearch = (url) => {
    const qdx = url.indexOf('?');
    const search = qdx > -1 ? url.substring(qdx + 1) : '';
    return search;
}

const parseParams = (search) => {
    let params = {},
        parts;

    if (search) {
        parts = search.split('&');

        parts.forEach(function handleQueryPart(part) {
            var param,
                key,
                val;
            param = part.split('=');
            key = param[0];
            if (param.length > 1) {
                val = decodeURIComponent(param[1]);
                if (params[key]) {
                    if (!Array.isArray(params[key])) {
                        params[key] = [params[key]];
                    }
                    params[key].push(val);
                }
                else {
                    params[key] = val;
                }
            }
            else {
                params[key] = true;
            }
        });
    }

    return params;
};

const parseQueryString = (url) => {
    const search = parseSearch(url);
    const params = parseParams(search);
    return params;
};

const getQueryParam = (key, url) => {
    const params = parseQueryString(url);
    return params[key];
};

export default getQueryParam;