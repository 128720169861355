import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Lottie from "react-lottie";

import { CustomImageInput } from "../sharedComponents";
// import facebookLogo from "../../assets/media/fb-icon.png";
import animationData from "../../assets/media/white-spinner.json";

import {
  signup,
  signupWithFacebook,
  getEmailAddressErrors,
  getUsernameErrors,
  getPasswordErrors,
  allFieldsValidated,
  sendGAEvent,
} from "../../services";

const RECAPTCHA_KEY = "6LffBTYUAAAAAOVu998M6lU1Y-yMY0bkyPjcccTj";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: {
        emailAddress: "",
        password: "",
        username: "",
      },
      errors: {
        emailAddress: "",
        password: "",
        username: "",
        facebookOrApple: "",
      },
      fbLoading: false,
      bbcomLoading: false,
    };

    this._reCaptchaRef = React.createRef();
  }

  setEmailAddressErrors = () => {
    getEmailAddressErrors(this.state.credentials.emailAddress)
      .then((res) => this.setError({ emailAddress: res }))
      .then(() => {
        if (this.state.credentials.password) {
          this.setPasswordErrors();
        }
      });
  };

  setUsernameErrors = () => {
    getUsernameErrors(this.state.credentials.username)
      .then((res) => this.setError({ username: res }))
      .then(() => {
        if (this.state.credentials.password) {
          this.setPasswordErrors();
        }
      });
  };

  setPasswordErrors = () => {
    getPasswordErrors(this.state.credentials).then((res) =>
      this.setError({ password: res })
    );
  };

  setError = (error) => {
    const newErrors = { ...this.state.errors, ...error };
    this.setState({ errors: newErrors });
  };

  getCaptcha = () => {
    return new Promise((success, reject) => {
      this._reCaptchaRef.current
        .executeAsync()
        .then(success)
        .catch((err) => {
          console.log("getting error from recaptcha");
          reject("ReCAPTCHA failed");
        });
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState((state) => {
      const credentials = Object.assign({}, state.credentials, {
        [target.name]: target.value,
      });
      return { credentials };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    sendGAEvent({
      event: "button_click",
      category: "profile",
      action: "create_account",
      label: "bbcom",
    });

    this.setState({ bbcomLoading: true });

    if (allFieldsValidated(this.state)) {
      console.log("trying to submit user");
      this._reCaptchaRef.current
        .executeAsync()
        .then((captchaValue) => {
          const { credentials } = this.state;
          credentials.gRecaptchaResponse = captchaValue;
          signup(credentials).catch((err) => {
            this.setError({ password: err.message });
          });
        })
        .catch((err) => {
          try {
            this.setError({ email: err.message });
          } catch (err) {
            this.setError({ email: "Recaptcha failed." });
          }

          this.setState({ bbcomLoading: false });
          sendGAEvent({
            event: "failure",
            category: "profile",
            action: "create_account_failed",
            label: "bbcom",
          });
        });
    }
  };

  handleFbSignup = async (e) => {
    e.preventDefault();
    sendGAEvent({
      event: "button_click",
      category: "profile",
      action: "create_account",
      label: "facebook",
    });

    console.log("trying to signup via facebook");

    if (!window.FB || !window.FB.getLoginStatus) {
      this.setError({ facebookOrApple: "Facebook is not connected." });
      return;
    } else if (window.location.hostname === "localhost") {
      this.setError({
        facebookOrApple: "Google ReCAPTCHA does not support localhost.",
      });
      return;
    }

    const handleFBError = (message) => {
      this.setError({ facebookOrApple: message });
      this.setState({ fbLoading: false });
    };

    this.setState({ fbLoading: true });
    signupWithFacebook(this.getCaptcha).catch((e) => {
      handleFBError(e.message);
    });
  };

  handleRecaptcha = () => {
    console.log("recaptcha successful");
  };

  render() {
    const { isSelected } = this.props;
    const { credentials, errors } = this.state;
    const hasErrors = !allFieldsValidated(this.state);
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div
        className={
          "combined-sign-in--signup " + (isSelected ? "" : "hide-on-mobile")
        }
      >
        <h3 className="hide-on-mobile combined-sign-in--desktop-tab">
          Create an Account
        </h3>
        <div>
          <form
            className="combined-sign-in--input-form"
            onSubmit={this.handleSubmit}
          >
            <CustomImageInput
              fieldType="emailAddress"
              onChange={this.handleInputChange}
              onBlur={this.setEmailAddressErrors}
              value={credentials["emailAddress"]}
              errors={errors.emailAddress}
              label="Email"
            />
            <div className="combined-sign-in--username-and-password">
              <CustomImageInput
                fieldType="username"
                onChange={this.handleInputChange}
                onBlur={this.setUsernameErrors}
                value={credentials["username"]}
                errors={errors.username}
              />
              <CustomImageInput
                fieldType="password"
                onChange={this.handleInputChange}
                value={credentials["password"]}
                onBlur={this.setPasswordErrors}
                errors={errors.password}
              />
            </div>
            <ReCAPTCHA
              ref={this._reCaptchaRef}
              sitekey={RECAPTCHA_KEY}
              onChange={this.handleRecaptcha}
              size="invisible"
            />
            <button
              className={
                "combined-sign-in--" +
                (hasErrors ? "disabled-button" : "button")
              }
              onClick={this.handleSubmit}
            >
              {this.state.bbcomLoading && (
                <div>
                  <Lottie options={defaultOptions} height={30} width={30} />
                </div>
              )}
              &nbsp;&nbsp;Create Account
            </button>
            <p className="combined-sign-in--create-account-terms">
              By clicking “Create Account” you agree to the
              Bodybuilding.com&nbsp;
              <a href="https://support.bodybuilding.com/en_us/terms-of-use-HyjAov2t2">
                Terms of Use
              </a>
              &nbsp;and&nbsp;
              <a href="https://support.bodybuilding.com/en_us/privacy-policy-Sy3bjw2Y3">
                Privacy Policy
              </a>
              .
            </p>
            <hr className="combined-sign-in--divider" />
          </form>
          <div className="combined-sign-in--alternate-signup">
            <div
              className={
                "combined-sign-in--errors " +
                (errors.facebookOrApple.length === 0 ? "hidden" : "")
              }
            >
              <p>{errors.facebookOrApple}</p>
            </div>
            <div className="combined-sign-in--alternate-signup--buttons">
              {/* <button
                className="combined-sign-in--facebook-button"
                onClick={this.handleFbSignup}
              >
                {this.state.fbLoading ? (
                  <div>
                    <Lottie options={defaultOptions} height={30} width={30} />
                  </div>
                ) : (
                  <img src={facebookLogo} alt="facebook logo" />
                )}
                &nbsp;&nbsp;Sign up
              </button> */}
            </div>
            <p>We will never post to Facebook without permission.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
