import React from 'react';

import './CustomImageInput.scss';
// import emailIcon from '../../../assets/media/email-icon.svg';
// import passwordIcon from '../../../assets/media/password-icon.svg';
// import usernameIcon from '../../../assets/media/username-icon.svg';
// import errorIcon from '../../../assets/media/error-icon.svg';
// import successIcon from '../../../assets/media/success-icon.svg';

// const typeToSVGMap = {
//     emailAddress: emailIcon,
//     password: passwordIcon,
//     username: usernameIcon,
// };

const upperCaseFirst = (str) => {
    const firstChar = /^[a-zA-Z]/;
    return str.replace(firstChar, str[0].toUpperCase())
};

class CustomImageInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            focus: false,
            beenTouched: false,
        }
    }

    onFocus = () => {
        this.setState({ focus: true, beenTouched: true });
    }

    onBlur = (e) => {
        this.setState({ focus: false });
        this.props.onBlur(e);
    }

    render() {
        const { fieldType, value, onChange, errors, noFeedback } = this.props;
        let { label } = this.props;
        const { onFocus, onBlur } = this;

        label = label ? label : upperCaseFirst(fieldType);

        const hasErrors = (errors || (this.state.beenTouched && !value)) && !this.state.focus;
        const success = (!errors && !this.state.focus && this.state.beenTouched && value);

        let className='combined-sign-in--custom-image-input--container '
        if (noFeedback) {
        } else if (this.state.focus || noFeedback) {
            className += 'combined-sign-in--input-field-focus';
        } else if (hasErrors) {
            className += 'combined-sign-in--input-field-error';
        } else if (success) {
            className += 'combined-sign-in--input-field-success';
        }

        const autoComplete = this.props.autoComplete ? 'on' : 'new-password';

        return (
            <div>
                <div className={ className } >
                    <label htmlFor={ fieldType }>
                        {/* <img
                            className='combined-sign-in--custom-image-input--img'
                            alt={ fieldType.toLowerCase() }
                            src={ typeToSVGMap[fieldType] } /> */}
                        <span className='combined-sign-in--custom-image-input--default'>
                            { value ? '' : upperCaseFirst(label) }
                        </span>
                    </label>
                    <input
                        type={ fieldType === 'password' ? 'password' : 'text' }
                        name={ fieldType }
                        required
                        className='combined-sign-in--custom-image-input--input'
                        onChange={ onChange }
                        onFocus={ onFocus }
                        onBlur={ onBlur }
                        value={ value }
                        autoComplete={ autoComplete }
                    />
                </div>
                { errors !== undefined &&
                <div className={
                    'combined-sign-in--errors '
                        + (errors.length === 0 ? 'hidden' : '')
                    }>
                    <p>{ errors }</p>
                </div>
                }
            </div>
        )
    }
};

export default CustomImageInput;
