import axios from 'axios';
// import md5 from 'md5';
// import { sha256 } from 'js-sha256';

import { multipassRedirect } from './util/shopify';
import { gaTrackLogin, sendGAEvent } from './util/tracking';

export const endpoint = 'https://api.bodybuilding.com';

// export const login = async (loginParams, getCaptcha) => {
//     const passwordHash = md5(loginParams.password);
//     let captchaResult;
//     const { data } = await axios.post(`${endpoint}/login`, { ...loginParams, password: passwordHash }, { withCredentials: true });
//     if (data.showCaptcha && getCaptcha) {
//         sendGAEvent({
//             event: "recaptcha",
//             category: "profile",
//             action: "login",
//             label: "recaptcha required",
//         });
//         captchaResult = await getCaptcha();
//     }
//     await verifyLogin(data, captchaResult);
// }

export const login = async (loginParams) => {
    try {
        const { data } = await axios.post(`https://api.bodybuilding.com/v2/login`, loginParams);

        if (data.user && data.user.shop_url) {
            window.location.href = data.user.shop_url; // Redirect to the shop URL
        }

        return data; // Return the data if needed elsewhere
    } catch (error) {
        console.error("Login failed:", error);

        // Extract error message properly
        let message = "An unexpected error occurred"; // Default message
        if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message; // Use server error message if available
        } else if (error.message) {
            message = error.message; // Use error message from axios
        }

        throw new Error(message); // Throw error so it can be caught in .catch()
    }
};


// const verifyLogin = async (data) => {
//     const loginVerificationParams = {
//         captchaResult,
//         challenge: data
//     };
//     let { dataToHash } = data;
//     for (let i = 0; i < data.iterations; i++) {
//         dataToHash = sha256(dataToHash); // Use only SHA256 for hashing
//     }
//     loginVerificationParams.hashResult = dataToHash;

//     return axios.post(`${endpoint}/login`, loginVerificationParams)
//         .then(loginSuccess)
//         .catch((err) => {
//             if (err.response && err.response.status === 401) {
//                 throw new Error(err.response.data.err_msg);
//             } else {
//                 throw new Error(err.message);
//             }
//         });
// }


export const loginSuccess = ({ data }) => {
    const { csrfLoginToken } = data;
    window.sessionStorage.setItem('bbCSRF', csrfLoginToken.csrf);
    window.sessionStorage.setItem('bbCSRFDate', new Date());
    return axios.post(`${endpoint}/api-proxy/rightsidebar/getinfo`, { needsuser: 1 }).then(verificationSuccess);
}

const getProfileAPI = () => {
    const defaultURL = `https://profile-api.bodybuilding.com/`;
    const apiLink = document.querySelector('link[rel="bb:profile-api-root"]');
    if (apiLink) {
        return apiLink.href
    }
    return defaultURL;
}

export const verificationSuccess = async () => {
    await gaTrackLogin();
    return axios.get(`${getProfileAPI()}profile/me`)
        .then((response) => {
            window.sessionStorage.setItem('currentUser', JSON.stringify(response.data));
            return multipassRedirect();
        });
}