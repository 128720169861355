import axios from 'axios';

const isValidEmailAddress = (emailAddress) => {
    return /^[a-zA-Z0-9\\._%+-]+@[a-zA-Z0-9\\.-]+\.[a-zA-Z]{2,4}$/.test(emailAddress);
};

const getBBCOMEmailErrors = (email) => {
    const endpoint = 'https://api.bodybuilding.com/profile/email/' + email;

    const arrayOfEmailErrors = new Promise((res, rej) => {
        axios
            .head(endpoint)
            .then((response) => res('Email address already exists.'))
            .catch((err) => {
                if (err.response) {
                    err.response.status === 404
                        ? res('')
                        : res(err.response.message);
                } else {
                    res('Unable to connect to bodybuilding.com.');
                }
            });
    });

    return arrayOfEmailErrors;
}

export const getEmailAddressErrors = (emailAddress) => {
    const emailAddressPromise = new Promise((res) => {
        if (!emailAddress) {
            res('Email address required');
        } else if (isValidEmailAddress(emailAddress)) {
            getBBCOMEmailErrors(emailAddress)
                .then((bbcomRes) => res(bbcomRes));
        } else {
            res('Invalid email address.');
        }
    });

    return emailAddressPromise;
}

export const emailAddressAlreadyExists = (email) => {
    const endpoint = 'https://api.bodybuilding.com/profile/email/' + email;

    const emailStatus = new Promise((res, rej) => {
        axios.head(endpoint)
            .then((response) => res(true))
            .catch((err) => {
                console.log(err);
                if (err.response) {
                    err.response.status === 404
                        ? res(false)
                        : rej(true);
                } else {
                    rej(true);
                }
            });
    });

    return emailStatus;
};