import axios from 'axios';

const isValidUsername = (username) => {
    return /^[a-zA-Z0-9]{3,15}$/.test(username);
};

const getBBCOMUsernameErrors = (username) => {
    const endpoint = 'https://api.bodybuilding.com/profile/slug/' + username;

    const arrayOfUsernameErrors = new Promise((res, rej) => {
        axios
            .head(endpoint)
            .then((response) => res('Username already exists.'))
            .catch((err) => {
                if (err.response) {
                    err.response.status === 404
                        ? res('')
                        : res(err.response.message)
                } else {
                    res('Unable to connect to bodybuilding.com.')
                }
            });
    });

    return arrayOfUsernameErrors;
}

export const getUsernameErrors = (username) => {
    const usernamePromise = new Promise((res) => {
        if (!username) {
            res('Username required');
        } else if (isValidUsername(username)) {
            getBBCOMUsernameErrors(username)
                .then((bbcomRes) => res(bbcomRes));
        } else {
            res('Invalid username: Please use only 3-15 numbers and/or letters.');
        }
    });

    return usernamePromise;
}
