import axios from 'axios';
import { emailAddressAlreadyExists } from './validation/emailService';
import { endpoint, loginSuccess } from './login';
import { createUser } from './signup';
import { gaTrackSignup } from './util/tracking';

// Logic for login and signup using Facebook

export const loginWithFacebook = async () => {
    await getFBLogin();
    const fbLoginStatus = await getFBLoginStatus();
    await loginWithFacebookIntoBB(fbLoginStatus);
}

export const signupWithFacebook = async (getCaptcha) => {
    const fbLogin = await getFBLogin();
    // getting captcha after login, otherwise fb login popup will be blocked
    const captcha = await getCaptcha();
    const credentials = await getFBCredentials(fbLogin, captcha);
    if (!credentials.emailAddress) {
        throw new Error('No email associated with this Facebook account.');
    }
    const fbLoginStatus = await getFBLoginStatus();
    const emailExistsInBBCOM = await emailAddressAlreadyExists(credentials.emailAddress);
    if (!emailExistsInBBCOM) {
        console.log(`creating user for fb account`, credentials);
        await createUser(credentials);
        await gaTrackSignup();
    }
    await loginWithFacebookIntoBB(fbLoginStatus);
}

// Helper function to login into BB using the credentials from FB

const loginWithFacebookIntoBB = async (facebookData) => {
    if (!facebookData) {
        throw new Error('Cannot connect to Facebook');
    } else if (!facebookData.userID || !facebookData.accessToken) {
        throw new Error('Facebook data didn\'t match expected');
    }
    const { accessToken, userID } = facebookData
    const params = {
        socialUserId: userID,
        accessToken: accessToken,
    };
    await axios.post(`${endpoint}/login/facebook`, params, { cache: false, withCredentials: true })
        .then(loginSuccess)
        .catch((err) => {
            if (err.response && err.response.status === 401) {
                throw new Error(err.response.data.err_msg);
            } else {
                throw new Error(err.message);
            }
        });
}

// Create credentials object for login from responses from FB apis

const buildCredentials = (fbLogin, fbMe, captcha) => {
    return {
        facebookId: fbLogin.authResponse.userID,
        facebookAccessToken: fbLogin.authResponse.accessToken,
        gRecaptchaResponse: captcha,
        emailAddress: fbMe.email,
    };
}

// Making promises from FB api calls to simplify logics

const getFBLoginStatus = () => {
    return new Promise((res) => {
        window.FB.getLoginStatus((loginStatus) => {
            res({ ...loginStatus.authResponse });
        });
    });
};

const getFBLogin = () => {
    var config_id = 1626561778167825;
    return new Promise((res) => {
        window.FB.login((fbLoginStatusRes) => {
            res(fbLoginStatusRes);
        }, {
            scope: 'email',
            config_id: config_id
        });
    });
}

const getFBMe = () => {
    return new Promise((res) => {
        window.FB.api('/me', 'GET', { 'fields': 'email' }, (fbRes) => {
            res(fbRes);
        });
    });
}

const getFBCredentials = async (fbLogin, captcha) => {
    if (fbLogin.status === 'connected') {
        const fbMe = await getFBMe();
        return buildCredentials(fbLogin, fbMe, captcha);
    }
    throw new Error('Cannot connect to Facebook.');
}



