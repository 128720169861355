import { useState, useEffect } from "react";
import '../CombinedSignin.scss';

const imageUrls = [
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bga.png?v=1739881916",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgb.jpg?v=1739881915",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgc.jpg?v=1739881916",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgd.jpg?v=1739881916",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bge.jpg?v=1739882052",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgf.jpg?v=1739882051"
];

const PasswordChange = () => {
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isChanging, setIsChanging] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [passwordsMatch, setPasswordsMatch] = useState(true);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	useEffect(() => {
		document.title = "Change Password | Bodybuilding.com";

		const interval = setInterval(() => {
			setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	const handleNewPasswordChange = (e) => {
		setNewPassword(e.target.value);
		setPasswordsMatch(e.target.value === confirmPassword);
	};

	const handleConfirmPasswordChange = (e) => {
		setConfirmPassword(e.target.value);
		setPasswordsMatch(newPassword === e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (newPassword !== confirmPassword) {
			setErrorMessage("Passwords don't match.");
			return;
		}
		setIsChanging(true);
		setErrorMessage("");

		try {
			// Simulating an API call
			await new Promise((resolve) => setTimeout(resolve, 2000));
			alert("Password changed successfully");
		} catch (error) {
			setErrorMessage("Failed to change password. Please try again.");
		} finally {
			setIsChanging(false);
		}
	};

	return (
		<div className="password-change-container combined-sign-in--container ">
			{/* Sidebar Image */}
			<div className="slider-container">
				{imageUrls.map((img, index) => (
					<img
						key={index}
						src={img}
						alt="Slideshow"
						className={`slider-image ${index === currentImageIndex ? "active" : ""}`}
					/>
				))}
			</div>

			{/* Password Change Form */}
			<div className="password-change-form">
				<div >
					<img
						src="https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bbcom-logos-tm-rgb-blue-wordmark_1.png?v=1710959472"
						alt="Bodybuilding.com Logo"
						className="combined-sign-in--logo"
					/>
					<h2>Change Your Password</h2>
					<p>Enter your current and then your new password.</p>

					<form className="password-form" onSubmit={handleSubmit}>
						<div className="input-group">
							<input
								className="input-box"
								type="password"
								placeholder="Current Password"
								value={currentPassword}
								onChange={(e) => setCurrentPassword(e.target.value)}
								required
							/>
						</div>
						<div className="input-group">
							<input
								className="input-box"

								type="password"
								placeholder="New Password"
								value={newPassword}
								onChange={handleNewPasswordChange}
								required
							/>
						</div>
						<div className="input-group">
							<input
								className="input-box"

								type="password"
								placeholder="Confirm New Password"
								value={confirmPassword}
								onChange={handleConfirmPasswordChange}
								required
							/>
						</div>
						<div className="combined-sign-in--errors">
							{!passwordsMatch && <p className="error-message">Passwords do not match!</p>}
							{errorMessage && <p className="error-message">{errorMessage}</p>}
						</div>
						

						<button type="submit" className="combined-sign-in--button" disabled={isChanging || !passwordsMatch}>
							{isChanging ? "Submitting..." : "Change Password"}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default PasswordChange;
