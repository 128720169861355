import axios from 'axios';
import getQueryParam from './getQueryParam';
import { getStoreName } from '@bbcom/bb-base/app/bb/base/services/store-service';
import { getRegionByDomain } from '@bbcom/bb-base/app/bb/base/services/region-service';
import { endpoint } from '../login';

const getStoreFromUrl = (url) => {
  // eslint-disable-next-line no-useless-escape
  const matchedUrl = url.match(/http[s]?:\/\/([^\/]+)/);
  if (!matchedUrl) {
      return null;
  }
  const hostname = matchedUrl[1];
  const region = getRegionByDomain(hostname);
  return region ? region.storeName : null;
}

export const multipassRedirect = async () => {
  const returnURL = getQueryParam(`referrer`, window.location.href) || window.document.referrer || 'https://www.bodybuilding.com';
  const storeFromReferrer = getStoreFromUrl(returnURL);
  const storeName = await getStoreName();
  const multipassParams = {
      storeName: storeFromReferrer || storeName,
      returnURL
  };
  return axios.post(`${endpoint}/profile/shopify/multipass-magic-link`, multipassParams, { withCredentials: true })
      .then(multipassResponse => {
          const multipassReturnUrl = multipassResponse.data.shopify_return_url.returnURL;
          if (multipassReturnUrl) {
              window.location.href = multipassReturnUrl;
          } else {
              throw new Error('Failed to login into the store');
          }
      });
}