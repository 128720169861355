import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { activateAccount } from "../services/activateService";
import '../CombinedSignin.scss';

const imageUrls = [
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bga.png?v=1739881916",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgb.jpg?v=1739881915",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgc.jpg?v=1739881916",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgd.jpg?v=1739881916",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bge.jpg?v=1739882052",
	"https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bgf.jpg?v=1739882051"
];

const Activate = () => {
	const [status, setStatus] = useState("loading");
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Activate Account | Bodybuilding.com";

		activateAccount()
			.then(() => {
				setStatus("success");
				setTimeout(() => navigate("https://www.bodybuilding.com"), 3000);
			})
			.catch(() => setStatus("failure"));
	}, [navigate]);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	return (
		<div className="combined-sign-in--container activate-container">
			{/* Sidebar Image */}
			<div className="slider-container">
				{imageUrls.map((img, index) => (
					<img
						key={index}
						src={img}
						alt="Slideshow"
						className={`slider-image ${index === currentImageIndex ? "active" : ""}`}
					/>
				))}
			</div>

			{/* Activation Message */}
			<div className="activate-message">
				<div className="form-di">
					<img
						src="https://cdn.shopify.com/s/files/1/0471/3332/7519/files/bbcom-logos-tm-rgb-blue-wordmark_1.png?v=1710959472"
						alt="Bodybuilding.com Logo"
						className="combined-sign-in--logo"
					/>
					{status === "loading" && <div className="Activate-loading">Loading...</div>}
					{status === "success" && (
						<span className="Activate-msg__success">
							Your account has been verified. Thanks!
						</span>
					)}
					{status === "failure" && (
						<span className="Activate-msg__failure">
							Your account could not be verified. Try reloading the page.
						</span>
					)}
					</div>
			</div>
		</div>
	);
};

export default Activate;
