import axios from 'axios';
import { verificationSuccess } from './login';
import { gaTrackSignup } from './util/tracking';

export const signup = async (credentials) => {
  await createUser(credentials);
  await gaTrackSignup();
  await verificationSuccess();
}

export const createUser = async (credentials) => {
  const endpoint = 'https://api.bodybuilding.com/profile/web';
  return axios.post(endpoint, credentials)
      .catch((err) => {
          throw new Error(err.response.data.message);
      });
}